/* Event List */
.container.event-list,
.container.event-stream {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
}

.container.event-list .events-header-text {
    font-size: 25px;
    padding-bottom: 25px;
}

.event-item {
    display: flex;
    flex-direction: row;
    border: 1px solid #CCC;
    padding: 25px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

    .event-item:first {
        margin-top: 0;
    }

    .event-item:hover {
        background-color: #ccc;
    }

    .event-time {
        padding-right: 25px;
    }

.events-header-text {
    color: #00346e;
    font-weight: bold;
}

.event-details {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

    .event-details .company-name {
        font-size: 14pt;
        font-weight: bold;
        margin-top: 10px;
    }

    .event-details .event-name {
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .event-logo img {
        max-height: 300px;
    }

    .event-name {
        font-weight: bold;
    }

.event-stream .site-body {
    display: flex;
    flex-direction: column;
}

.event-stream .options {
    display: flex;
    flex-direction: column;
    /* width: 450px; */
}

.event-stream .options button {
    margin-left: 15px;
}