html, body {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    flex: 1;
    flex-direction: column;
    font-family: "Arial";
}

#root {
    width: 100%;
}

.site-header-top {
    min-height: 65px;
    width: 100%;
    padding: 20px 0;
    background-color: #0057B8;
    -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,.4);
    -moz-box-shadow: 0 0 3px 0 rgba(0,0,0,.4);
    box-shadow: 0 0 3px 0 rgba(0,0,0,.4);
}

    .header-nav-bar {
        padding: 0 20px;
    }

    .header-nav-bar .container-fluid {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
        .header-nav-bar .options {
            align-items: center;
        }
/* text for signoutbutton */
        .header-nav-bar .signout-button {
            color: #000;
        }

    .header-logo img {
        max-height: 100px;
    }

.site-body {
    display: flex;
    flex: 1;
    justify-content: center;

    background: url(/img/Background.jpg) 50% 0 fixed no-repeat;
    background-size: cover;
}

    .site-body-content {
        margin: 25px;
        background-color: #FFF;
        padding: 25px;
        width: 50%;
    }

.footer-bottom {
    background-color: #0057B8;
    padding: 15px 0;
    color: #FFF;
    text-align: center;
}

    .footer-bottom a {
        text-decoration: none;
        color: #FFF;
    }

.player {
    padding-top: 1%;
    /* margin: 5em auto; */
    padding-right: 10%;
    padding-left: 10%;

}
.row {
  display: flex !important;
    flex-direction: column;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  padding: 10px;
}
.banner {
    justify-content: center;
	display: flex;
    padding-top: 1% !important;

    /* margin: 5em auto !important; */

}
.left {
  width: 75%;
}

.right {
  width: 25%;
}