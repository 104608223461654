html, body {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: #fff;
/* 	background-color: #0057b8; */
    font-family: "Arial";
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    
}

.container.signin {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    
}

.header-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0px;
    background-color: #0057B8 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    
}

.header-row > div {
    margin-bottom: 15px;
}

    .header-row img {
        max-width: 70%;
    }

.logo {
    width: 80%;
    max-width: 80%;

}

.MuiInputBase-input {
	background-color: white !important;

}

.MuiButton-containedPrimary {
    background-color: #e0e0e0 !important;
    
}

.signin-button {
    margin-top: 15px !important;
    height: 45px !important;

    /* border: 1px solid #C0A772 !important; */
    background: #fff !important;
    color: #000 !important;
}

    .signin-button:hover {
        background: #ccddf0 !important;
        /* border: 1px solid #a47d42; */
        color: #FFF !important;
    }

.mb-4 {
    height: auto;
    width: 200px;
    
}

.title-text {
    font-weight: 400 !important;
    font-size: 1.5rem;
    margin-bottom: 1rem !important;
    text-align: center;
    background-color: #0057B8 !important;
    color: #fff;
    
}

.signin-error {
    color: red;
    padding: 15px 0;
    text-align: center;
}

#sponsors {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.donate {
    padding-left: 10%;
    padding-right: 10%;
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {

    .container.signin {
        padding: 0 25px;
    }

    .site-header-top .container-fluid {
        flex-direction: column !important;
    }

        .site-header-top .header-nav-bar .options {
            margin-top: 15px;
        }

    .site-body-content {
        width: 90% !important;
    }

        .event-item {
            flex-direction: column !important;
        }

        .event-logo img {
            max-width: 100%;
        }

        .event-item .event-details {
            margin-left: 0 !important;
        }

        .player {
            padding-left: 0%;
            padding-right: 0%;
        }
}